.photo{
    border: 1px solid #6E6E6E;
    background-color: #fff;
    width: 100vh;
    height: 275px;
    background-size: contain;
    object-fit: contain;
    background-repeat: no-repeat;
     margin-left: 200px;
     margin-top: 100px;
}
.photcont{
    width: 98.5vh;
    height: 98%;
    margin: 2px;
    border: 1px solid lightgray;
}
.onw{
    font-family: 'Arial', sans-serif;
    text-align: center;
display: flex; 
align-items: center;
font-size: 10px;
padding-top:10px;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
}
.onw1{
    font-weight: bold;
    color:#7556A4;
    border-radius: 10px;
    display:flex;
}
.onw2{
    font-size: 10px;
}

.photo h1{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.photo h3{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 9px;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.namess{
 
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 600;
}
 h4{
    font-size: 26px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
   
}
h5{
 
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 40px;
    font-weight: 600;
}
