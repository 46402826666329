.slider_div{
    width: 57%;
    height: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
}

article{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    opacity: 0;
    transition: all .5s ease;
}

article img{
    width: 130px;
    height: 130px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    border:  3px solid #ccc;
}

.review_name{
    margin-top: 40px;
    font-size: 18px;
    text-transform: capitalize;
    color: #333;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 300;
}

.review_add{
    margin-top: 5px;
    font-size: 15px;
    text-transform: capitalize;
    color: #7556A4;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
}

.review_text{
    color: #666;
    margin-top: 35px;
    width: 80%;
    font-size: 16.5px;
    text-align: center;
    line-height: 28px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 300;
}

.leftSlide{
    transform: translateX(100%);
}

.activeSlide{
    transform: translateX(0);
    opacity: 1;
}

.lastSlide{
    transform: translateX(-100%);
}

.slider_div button{
    font-size: 45px;
    background-color: transparent;
    outline: none;
    border: none;
    color: silver;
    cursor: pointer;
    z-index: 100;
}


@media(max-width:450px){
    .slider_div{
        width: 100%;
        height: 360px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        overflow: hidden;
    }
    
    article{
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        opacity: 0;
        transition: all .5s ease;
    }
    
    article img{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        border:  3px solid #ccc;
    }
    
    .review_name{
        margin-top: 30px;
        font-size: 16px;
        text-transform: capitalize;
        color: #333;
    }
    
    .review_add{
        margin-top: 5px;
        font-size: 14px;
        text-transform: capitalize;
        color: #7556A4;
    }
    
    .review_text{
        color: #666;
        margin-top: 30px;
        width: 80%;
        font-size: 15px;
        text-align: center;
        line-height: 26px;
    }
    
    .leftSlide{
        transform: translateX(100%);
    }
    
    .activeSlide{
        transform: translateX(0);
        opacity: 1;
    }
    
    .lastSlide{
        transform: translateX(-100%);
    }
    
    .slider_div button{
        font-size: 35px;
        background-color: transparent;
        outline: none;
        border: none;
        color: silver;
        cursor: pointer;
        z-index: 100;
    }
}