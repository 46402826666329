.registercont{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}
.navs{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
     width: 100%;
}
.formcont{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.formcont form{
    width: 36%;
    border-radius: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.formcont form h1{
    margin: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: 30px;
}
form label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 32px;
}
.spaces{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}
form label p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 20px;
}
form label h6{
    font-weight: 350;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
label input{
    width: 100%;
    padding: 15px;
    border: 1px solid #E7E9EC;
    outline: none;
    border-radius: 10px;

}
label input:focus{
    border: 1px solid  #80BDFF;
}
.btns{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
form button{
    width: 50%;
    padding: 20px;
    border: none;
    outline: none;
    border-radius: 30px;
    cursor: pointer;
    background-color: #7556A4;
    color: #fff;

}
form  button:hover{
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
}



.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    margin: 0 4px;
    background-color: #fff;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out both;
  }
  
  .dot:nth-child(1) {
    animation-delay: -0.32s;
  }
  
  .dot:nth-child(2) {
    animation-delay: -0.16s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }
  
@media(max-width:450px){
    .formcont form{
        flex: 1;
        box-shadow: none;
    }
    .closecont{
        position: absolute;
        right: 30px;
        top: 50px;
    }
    .closecont img{
        width: 20px;
    }
}
@media(max-width:340px){
    form label h6{
        font-size: 12px;
    }
    .closecont{
        top: 40px;
    }
}