.groupcont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.studentgroupheader{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 100;
    font-size: 15px;
    background-color: #000;
    color: #fff;
    cursor: pointer;
}
.closebtnd{
    width: 100%;
    display: flex;
padding: 20px;
cursor: pointer;
margin-bottom: 20px;
}
.messcontsd{
    width: 100%;
    height: 80vh !important;

    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}
.messcontsd::-webkit-scrollbar-button{
    display: flex;
    width: 10px;
}
.inputcont{
    display: flex;
    align-items: center;
    flex: 1;
}
.senmess{
    cursor: pointer;
    width: 10%;
    margin-left: 15px;
}