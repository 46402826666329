.restcont{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: whitesmoke;
}
.restcont h4{
    margin-bottom: 20px;
}
.resetchild{
    min-width: 40%;
    background-color: #fff;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 20px;
}
.resetchild label{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 100;
    font-size: 20px;
}
.resetchild form{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.resetchild input{
    width: 95%;
    padding: 10px;
    margin: 20px 0px; 
    border: 1px solid #000;
    outline: none;
}
.resetchild input:focus{
    border: 1px solid lightblue;
}
.mb{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
/* forget */
.forgetcont{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.forgetchild{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    min-height: 400px;
}
.forgetchild h4{
    font-size: 30px;
    font-weight: 400;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 30px;
}
.forgetchild form{
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.mbm{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center ;
}
.mbm label{
    margin: 0px;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 20px;
}

form .mbm input{
    width: 50%;
    padding: 10px;
    outline: none;
    border: 1px solid #000;
}
form .mbm input:focus{
    border: 1px solid lightblue;
}
.forgetcont button{
    width: 25%;
    margin: 20px 0px;
    cursor: pointer;
}
.forgetcont button:hover{
    transform: scale(1);
     background-color: #000 !important;
}
.resetchild form .checkboxs{
    width: 100% !important;
     display: flex;
     gap: 15px;
    justify-content: flex-start !important;
    align-items: center !important;
    font-size: 20px;
    margin: 20px 0px;
}
.checkboxs input{
    margin: 0;
    width: fit-content;
}
/* verification */
.verificationcont{
width: 100%;
height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center; 
background-color: #FDFBF9;
}
.verificationcont h2{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 300;
    font-size: 25px;
    word-wrap: break-word;
}
.verificatechild p{
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
}
.verificatechild button{
    margin-top: 10px;
    width: 150px;
    padding: 15px;
    background-color: #AAC8F3;
    color: #fff;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
}
.verificatechild button:hover{
    background-color: #000;
}
.verificatechild{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
/* passwordconfirmed */
.passconfcont{
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
background-color: #F4F7FF;
}
.passchild{
    min-width: 30%;
    min-height: 400px;
    border-radius: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}
.passchild img{
    margin-top: 30px;
}
.passchild h2{
    color: gray;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 300;
    font-size: 20px;
    margin-top: 15px;
}
.passchild h3{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 21px;
    font-weight: 400;
}
.passchild button{
    width: 50%;
    margin-top: 10px;
    border-radius: 10px;
    outline: none;
    border: none;
    padding: 20px;
    margin-top: 10px;
    background-color: #419AEE;
    color: #fff;
    cursor: pointer;
}
.passchild button:hover{
    background-color: #AAC8F3;
}
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    margin: 0 4px;
    background-color: #fff;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out both;
  }
  
  .dot:nth-child(1) {
    animation-delay: -0.32s;
  }
  
  .dot:nth-child(2) {
    animation-delay: -0.16s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }

@media(max-width:900px){
    .resetchild{
        width: 70%;
    }
}

@media(max-width:750px){
    .resetchild{
        width: 95%;
    }
    .passchild{
        width: 85%;
     }
     .passchild h2{
        font-size: 25px;
     }
     .passchild h3{
        font-size: 27px;
     }
     .passchild button{
        width: 70%;
     }
     .forgetcont{
        background-color: whitesmoke !important;
     }
}
@media(max-width:450px){
 .verificationcont h2{
    font-size: 18px;
    font-weight: 500;
 }
 .verificatechild{
    padding: 10px;
 } 
 .mbm{
    width: 95% !important;
 }  
 form .mbm input{
    width: 95%;
 }
 .forgetcont label{
    width: 95%;
 }
 .forgetcont button{
    width: 60%;
 }
 .passchild{
    width: 95%;
 }
 .passchild h2{
    font-size: 25px;
 }
 .passchild h3{
    font-size: 27px;
 }
 .passchild button{
    width: 70%;
 }
}
@media(max-width:330px){
    .passchild h2{
        font-size: 20px;
    }
    .passchild h3{
        font-size: 22px;
    }
}