.profiless{
    display: flex;
    flex-direction: column;
    background-color: whitesmoke ;
    width: 100%;
    min-height: 100vh ;
    align-items: center;
    max-height: 500vh;
}
.profclose{
    display: flex;
    width: 80%;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 10px 0px;
}
.ess{
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ess h1{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 30px;
}
.dest1{
    display: flex;
    width: 50%;
    height: 430px;
    background-color:#fff ;
    padding: 10px;
    margin-top: 10px;
    border-radius: 20px;
    gap: 40px;
}
.des1i .avatar{
    width: 150px;
    height: 150px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 500;
} 
.des1i{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;
    justify-content: center;
    align-items: center;
}
.des1i button{
    width: 150px;
    padding: 10px;
    background-color: #BDBDBD   ;
    color: #fff;
    outline: none;
    border: none;   
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    font-weight: 200;
    cursor: pointer;
    border-radius: 20px;
}
.des1i button:hover{
    background-color: #000;
    color: #fff;
}
.deslii{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 10px;
}
.deslii label{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 200;
    font-size: 20px;
}
.deslii button{
    width: 150px;
    padding: 10px;
    background-color: #2E1C49;
    color: #fff;
    outline: none;
    border: none ;
    border-radius: 5px;
    cursor: pointer;
}
.themss button{
    width: 150px;
    padding: 10px;
    background-color: #2E1C49;
    color: #fff;
    outline: none;
    border: none ;
    border-radius: 5px;
    cursor: pointer;
}
.dest2{
    width: 50%;
    background-color: #fff;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 100px;
    max-height: max-content;
}
.activitiescont{
    display: flex;
    align-items: center;
    border: 1px solid #000;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
    gap: 50px;
}

.activitiescont img{
    width: 150px;
    height: 100px;
    border-radius: 10px;
}
.activitiescont div{
    display: flex;
    flex-direction: column;
}
.activitiescont div p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 500;
}
.activitiescont div h3{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: 500;
}
.frees{
    width: 10%;
    height: 25px;
    background-color: green;
     display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 15px;
}
.dest2 h1{
    margin: 10px 0px; 
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform:uppercase;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 30px;
}
.cancles{
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.noactivies{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 20px;
}
.noactivitiesbtn{
    width: 150px;
    padding: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    margin-bottom: 50px;
    border-radius: 15px;
}
/* admin seeting */
.themss{
    width: 50%;
    height: 100px;
    margin: 50px 0px;
    border-radius: 20px;
    background-color: #000;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.themss div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.themss p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 25px;
}
.addauser{
    display: flex;
    flex-direction: column;
    width: 50%;
    min-height: 150px;
    margin-top: 20px;
    margin-bottom: 50px;
    border-radius: 20px;
    padding: 10px;
    background-color: #000;
    justify-content: center;
    align-items: center;
}
.addauser div .createss{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 30px 0px;
}
.addauser .divs{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.addauser div .createss h2{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 20px;
}
.addauser div .createss button{
    width: 150px;
    padding: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    cursor: pointer;
    background-color: #2E1C49;
    color: #fff;
    border: none;
    border-radius: 10px;
}
.creatusers{
    width: 50% !important;
}
@media(max-width:450px){
    .dest1{
        display: flex;
        flex-direction: column;
        width: 95%;
        height: 740px;
        gap: 20px   ;
    }   
   .ess{
    margin-top: 5px;
   }
    .avatar{
        margin-top: 50px;
    }
   .deslii{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

   }
   .dest2{
    width: 100%;
   }
   .activitiescont{
    gap: 7px;
   }
   .cancles{
    width: 10%;
   }
   .cloxe{
    font-size: 40px !important;
   }
   .themss{
    width: 90%;
   }
   .creatusers{
    width: 100% !important;
}
.addauser{
    width: 95%;
}
}
@media(max-width:330px){
 .activitiescont{
    gap: 2px;
 }  
 .activitiescont img{
    width: 100px;
    height: 80px;
 }
 .actdet p{
   font-size: 15px !important;
  }
.cloxe{
    font-size: 25px !important;
}
}