.democont{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.banner{
    background-image: url("../images/banner.jpg");
    width: 100%;
    background-repeat: no-repeat;
    height: 100vh !important;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex: 1;
}
.conbanl h1{
    font-size: 55px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
}
.conbanl p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.banbtn{
    margin-top: 10px;
    display: flex;
    gap: 10px;
}
.banbtn .btn1{
    width: 160px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7556A4;
    color: #fff;
    border-radius: 30px;
    outline: none;
    border: none;
    cursor: pointer;
}
.banbtn .btn1:hover{
    background-color: black;
    color: #fff;
}

.banbtn .btn2{
    width: 160px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: darkorange;
    border-radius: 30px;
    outline: none;
    border: 1px solid darkorange;
    cursor: pointer;
}
.banbtn .btn2:hover{
   background-color: darkorange;
   color: #fff; 
}
.featured{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.featheader{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.featheader h1{
    color: #090930;
    font-size: 40px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    margin-bottom: 5px;
}
.featheader p{
    color:#838285 ;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 350;
    font-size: 15px;
}
.cosdetcont{
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}
.cosbtn{
    width: 100%;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cosbtn button{
    background-color: #7556A4;
    color: #fff;
    width: 150px;
    padding: 15px;
    outline: none;
    border: none;
    border-radius: 50px;
    cursor: pointer;
}
.cosbtn button:hover{
    transform: scale(1.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
}
.bannscourse{
    width: 100%;
    background-color: #F4F7FC;
    display: flex;
    justify-content: space-evenly;
    align-items: center !important;
    flex-wrap: wrap;
    padding: 20px;
    gap: 20px;
}
.bannscourse h2{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 25px;
}
.bannscourse h5{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 350;
    font-size: 15px;
}
.closs{
    display: flex;
    flex-direction: column;
    width: 150px;
    justify-content: center;
    align-items: center;
}
.chooseus{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 20px 0px;
}
.chooseus .choosefirst{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
}
.chooseus .choosefirst1{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
}

.choosefirst h1{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 450;
    font-size: 30px;
    font-style: italic;
}
.choosefirst1 p{
    width: 100%;
    word-wrap: break-word;
    word-break: break-all;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.choosefirst1 h1{ 
    width: 100%;
   display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 450;
    font-size: 30px;
    font-style: italic;
}
.choosefirst1 p{
    word-wrap: break-word;
    word-break: break-all;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.choosesecond{
    margin-top: 60px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.choosesecond .frustvide{
    height: 50vh !important;
}
.review{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.review h1{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 450;
    font-size: 30px;
    font-style: italic;
    margin: 30px 0px;
}
@media(max-width:950px){
  .banner{
    height: 470px;
  }

  .conbanl h1{
    font-size: 35px;
  }
    .conbanr img{
      margin-top: 45px;
        width: 400px;
    }
}
@media(max-width:750px){
.banner{
    height: 600px;
}
    .conbanl h1{
        font-size: 45px;
        
    }
    .conbanr{
    display: none;
}    
.banbtn{
    margin: 25px 0px;
}
.featheader p{
    margin-left: 5px;
}
.chooseus{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
}
.choosefirst{
    width: 100% !important;
}
.choosesecond{
  width: 100%;
}
.choosesecond .frustvide{
    height: 30vh !important;
}
}

@media(max-width:320px){
.conbanl h1{
    font-size: 30px;
}    
.conbanl p{
    font-size: 15px;
}
.banbtn .btn1{
    width: 150px;
}
.banbtn .btn2{
    width: 150px;
}

}