*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
header{
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 70px;
    margin-top: 15px;
    background-color:white;
    box-shadow: 3px 3px 2px 0px rgba(51, 51, 51, 0.5);
}

.logo {
    font-family: 'Arial', sans-serif;
    text-align: center;
display: flex; 
align-items: center;
cursor: pointer;
}
  
.f-letter {
    font-size: 20px;
    font-weight: bold;
    color:#7556A4;
    border-radius: 10px;
    display:flex;
    width: fit-content;
 }
  
.logo .text {
    margin-top: 3px;
    font-size: 15px;
    font-weight: bold;
    color: #2c3e50;
}

.achors{  
    display: flex;
    align-items: center;
      gap: 20px;
}    
.achors a{
    text-decoration: none;
    color: #000;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 200;
    cursor: pointer;
}
.achors a:hover{
    color: #2c3e50;
    font-weight: 400;
}
.achors .signup{
    color: white;
    background-color: #7556A4;
    width: 75px;
    height: 30px;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.achors  .signup:hover{
    background-color: #000;
    color: #fff;
    font-weight: 200 !important;
}

.navc2{
    display: flex;
    position: relative;
}
.navc2 div{
    position: absolute;
    top: -5px;
    left: 20px;
    width: 15px;
    height: 15px;
     display: flex;
     justify-content: center;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 10px;
}

@media(max-width:340px) {
.f-letter {
        font-size: 15px;
        font-weight: bold;
        color:#7556A4;
        border-radius: 10px;
        display:flex;
        width: fit-content;
     }
      
.text {
        margin-top: 3px;
        font-size: 11px;
        font-weight: bold;
        color: #2c3e50;
      }
.achors .login{
    display: none;
}
}