.allcoursecont{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.allcoursecont h1{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 30px;
    margin: 30px 0px;
    font-style: oblique;
}
.cont{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.logo {
    font-family: 'Arial', sans-serif;
    text-align: center;
display: flex; 
align-items: center;
}
  
.f-letter {
    font-size: 20px;
    font-weight: bold;
    color:#7556A4;
    border-radius: 10px;
    display:flex;
    width: fit-content;
 }
  
.text {
    margin-top: 3px;
    font-size: 15px !important;
    font-weight: bold;
    color: #fff;
  }
.footer{
    width: 100%;
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #1E1D2C;
    cursor: pointer;
    
}
.footer span{
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 21px;
}
.firsts{
    color: whitesmoke;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13.5px;
}
.seconds{
    color: whitesmoke;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13.5px;
}
.thirds{
    color: whitesmoke;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13.5px;
}
.forths{
    color: whitesmoke;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13.5px;
}

@media(max-width:450px){
    .footer{
        display: flex;
        flex-direction: column;
        padding: 5px;
    }
    .firsts{
        margin: 10px 0px;
        font-size: 17px;
    }
    .seconds{
        margin: 10px 0px;
        font-size: 17px;
    }
    .thirds{
        margin: 10px 0px;
        font-size: 17px;
    }
    .forths{
        margin-bottom: 10px;
        font-size: 17px;
    }
}