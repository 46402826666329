.pageconts{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagescent{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.pagescent h1{
    color:#C2ABD9;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: 90px;
}
.pagescent p{
    color: #000;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 25px;
}
.pagescent span{
    color: #000;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 300;
    font-size: 18px;
}
.pagescent button{
    width: 200px;
    padding: 20px;
    margin-top: 20px;
    border-radius: 20px;
    border: none;
    outline: none;
    background-color: #64A8CF;
    color: #fff;
    cursor: pointer;
}
.pagescent button:hover{
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
