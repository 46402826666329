*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.hbans{
    width: 100%;
    height: 500px;
    background-image:linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../image8/bannerss.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}
.hbans h1{
    
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 30px;
  
}
.hdetal{
    width: 600px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 20px;
}
.hdetal button{
    width: 200px;
    padding: 25px;
    border: none;
    outline: none;
    background-color: #2E1C49;
    color: #fff;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)    
}
.hmmimg{
    margin-right: 80px;
    margin-bottom: 20px;
}
.hcourses h1{
    padding-top: 20px;
}
.chatss{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 100px;
    right: 160px;
    background-color: #fff;
}
.chatcontainers{
    display: flex;
    align-items: center;
    flex-direction: column;
    position: fixed;
    bottom: 110px;
    right: 120px;
    width: 400px;
    height: 415px;
    border: none;
    border-radius: 20px;
    background-color: #000;
}
.chatinformations{
    width: 100%;
    height: 200px;
    padding: 10px;
    background-color: #2E1C49;
    color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    flex-direction: column;
}
.chatinformations p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 15px;
}
.chatbox{
    position: absolute;
    top: 100px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 250px;
    border-radius: 15px;
    overflow-y: scroll;
    cursor: pointer;
}
.chatbox::-webkit-scrollbar{
    appearance: none;
    width: 10px;
    height: 4px;     
}

.chatbox::-webkit-scrollbar-thumb{
    background-color:#000;
    border-radius: 1px
}
.chatinnput{
    position: absolute;
    top: 360px;
    display: flex;
    width: 100% ;
    justify-content: flex-start;
    align-items: center;
    padding-left: 25px;
    gap: 15px;
}
.chatinnput button{
    background-color: #000 !important;
    outline: none;
    border: none;
    cursor: pointer;
    width: fit-content;
    padding: 0px;
}

.chatinnput input{
    width: 86%;
    padding: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    border: 1px solid #fff;
    outline: none;
    border-radius: 3px;
}
.chatinnput input:focus{
    border: 1px solid #000;
}

.chatsschildss{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-weight: 400;
   font-size: 16px;
   background-color: #2E1C49;
   color: #fff;
    padding: 10px;
    border-radius: 10px;
    gap: 5px;
    cursor: pointer;
}
.messagecont{
    max-width: 50%;
    padding: 5px;
    width: 200px;
    border-radius: 5px;
    margin: 10px;
    word-break: break-all;
}
.chatscloses{
    display: none;
}
@media(max-width:950px){
   .hmmimg img{
    width: 300px;
   } 
}
@media(max-width:750px){
    .hmmimg{
        display: none;
    }
    .hbans h1{
        margin-top: 20px;
    }
    
    .chatss{
        display: flex;
        position: absolute;
        bottom: 100px;
        right: 180px;
    }
 .chatcontainers{
    width: 100% !important;
    height: 100% !important;
    position: fixed;
    bottom: 0px;
    right: 0px;
    top: 0;
    width: 400px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
 }   
 .chatinformations{
    height: 150px;
 }
 .chatbox{
    position: static;
    height: 60%;
    margin-bottom: 20px;
 }
.chatinnput{
    position:static ;
}
.chatscloses{
    display: flex;
}
}
@media(max-width:450px){
    .chatss{
        display: flex;
        position: absolute;
        bottom: 100px;
        right: 110px;
    }
    .sms{
        font-size: 60px !important;
    }
    .chatsschildss h1{
        display: none;
    }    
}
@media(max-width:340px){
.hbans h1{
    width: 300px;
    padding: 10px;
}

.chatss{
    display: flex;
    position: absolute;
    bottom: 90px;
    right: 78px;
}
.sms{
    font-size:40px !important;
}
.chatsschildss h1{
    display: none;
}
.chatinformations{
    height: 120px;
}
.cancless{
    font-size: 30px !important;
}
}